import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import preset from './default-preset/preset'
import en from '../../assets/i18n/en.json'
import ar from '../../assets/i18n/ar.json'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  rtl: localStorage.getItem('rtl') || false,
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    locales: {
      en,
      ar,
    },
    current: 'ar',
  },
  theme: {
    dark: false,
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
