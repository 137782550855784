import i18n from '@/i18n'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home.vue'
import About from '../views/pages/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - الرئيسية'
        } else {
          document.title = 'Tebaba - Home'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About,
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()

        if (i18n.locale === 'ar') {
          document.title = 'طبابا - من نحن'
        } else {
          document.title = 'Tebaba - About'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/solutions',
    name: 'Solutions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Solutions.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - الحلول'
        } else {
          document.title = 'Tebaba - Solutions'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Products.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/safer/:id',
    name: 'safer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/saferdetails.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/bedfont/:id',
    name: 'bedfont',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/ProductDetails.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/gastrolyzer',
    name: 'Gastrolyzer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Gastrolyzer.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/potok',
    name: 'ProductDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/potok.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/safer',
    name: 'ProductDetails',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/safer.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/bedfont',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/bedfont.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },
  {
    path: '/products/:id',
    name: 'Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Product.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - منتجاتنا'
        } else {
          document.title = 'Tebaba - Products'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },

  {
    path: '/contactus',
    name: 'Contact Us',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Contact.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - تواصل معنا'
        } else {
          document.title = 'Tebaba - Contact Us'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
  },

  {
    path: '/auth',
    name: 'Auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pages/Auth.vue'),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('access_token') || ''
      if (!token) {
        next()
        if (i18n.locale === 'ar') {
          document.title = 'طبابا - تسجيل الدخول'
        } else {
          document.title = 'Tebaba - Auth'
        }
      } else {
        next({
          name: 'Auth',
        })
      }
    },
    meta: {
      hideNavbar: true,
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
})

export default router
