<template>
  <v-app>
    <div>
      <navbar v-if="!$route.meta.hideNavbar"></navbar>
      <v-main>
        <router-view />
      </v-main>
      <div class="foot" v-if="!$route.meta.hideNavbar">
        <Footer></Footer>
      </div>
    </div>
  </v-app>
</template>

<script>
import Navbar from '@/components/NavbarII.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .loader-items {
    width: 100%;
    min-height: 60vh;
    .item {
      animation: rotate-all 2s 0 infinite linear alternate;
    }

    .div1 {
      width: 80px;
      height: 80px;
      background-color: #33a4d5;
      position: relative;
      margin: auto;
      animation: square-to-circle 2.5s 0.5s infinite cubic-bezier(1, 0.015, 0.295, 1.225) alternate-reverse;
    }

    .div2 {
      width: 80px;
      height: 80px;
      background-color: #006aa5;
      position: relative;
      margin: auto;
      animation: square-to-circle2 2.5s 0.42s infinite cubic-bezier(1, 0.015, 0.295, 1.225) alternate-reverse;
    }

    #one {
      top: 150px;
    }

    #two {
      top: 150px;
      right: 80px;
    }

    #three {
      top: 150px;
    }

    #four {
      bottom: 10px;
      left: 80px;
    }

    @keyframes square-to-circle {
      0% {
        border-radius: 0 0 0 0;
        background: #33a4d5;
        transform: rotate(45deg);
      }
      25% {
        border-radius: 50% 0 0 0;
        background: #33a4d5;
        transform: rotate(135deg);
      }
      50% {
        border-radius: 50% 50% 0 0;
        background: #006aa5;
        transform: rotate(180deg);
      }
      75% {
        border-radius: 50% 50% 50% 0;
        background: #00466c;
        transform: rotate(315deg);
      }
      100% {
        border-radius: 50%;
        background: #33a4d5;
        transform: rotate(404deg);
      }
    }

    @keyframes square-to-circle2 {
      0% {
        border-radius: 0 0 0 0;
        background: #33a4d5;
        transform: rotate(45deg);
      }
      25% {
        border-radius: 0 0 50% 0;
        background: #33a4d5;
        transform: rotate(135deg);
      }
      50% {
        border-radius: 0 0 50% 50%;
        background: #006aa5;
        transform: rotate(180deg);
      }
      75% {
        border-radius: 50% 0 50% 50%;
        background: #00466c;
        transform: rotate(315deg);
      }
      100% {
        border-radius: 50%;
        background: #33a4d5;
        transform: rotate(404deg);
      }
    }
  }
}
</style>
