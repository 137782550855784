<template>
  <div class="footer">
    <v-row justify="center" class="footer-wrap container mx-auto">
      <v-col cols="12" lg="3" md="12" sm="12" class="brief">
        <div class="bb-sec">
          <router-link to="/"
            ><v-img src="../assets/images/tebaba/foot.png" alt="Tebaba" max-width="200px" class="mx-auto"></v-img
          ></router-link>
          <div class="social-links mt-6">
            <ul class="d-flex justify-center align-center mb-0">
              <li class="slink mx-1">
                <router-link to="" class="mx-1">
                  <v-icon color="#fff" small>{{ icons.facebook }}</v-icon>
                </router-link>
              </li>
              <li class="slink mx-1">
                <router-link to="" class="mx-1">
                  <v-icon color="#fff" small>{{ icons.twitter }}</v-icon>
                </router-link>
              </li>
              <li class="slink mx-1">
                <router-link to="" class="mx-1">
                  <v-icon color="#fff" small>{{ icons.linked }}</v-icon>
                </router-link>
              </li>
              <li class="slink mx-1">
                <router-link to="" class="mx-1">
                  <v-icon color="#fff" small>{{ icons.insta }}</v-icon>
                </router-link>
              </li>
              <li class="slink mx-1">
                <router-link to="" class="mx-1">
                  <v-icon color="#fff" small>{{ icons.ytube }}</v-icon>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </v-col>
      <v-col lg="9" md="12" sm="12" class="links">
        <v-row justify="center">
          <v-col cols="6" lg="3" md="4" sm="4">
            <h5>{{ $t('Footer.links') }}</h5>
            <ul class="">
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/" class="d-flex align-items-center">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Home') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/about" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('About') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/services" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Products') }}
                </router-link>
              </li>

              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/contactus" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Contact') }}
                </router-link>
              </li>
            </ul>
          </v-col>
          <v-col cols="6" lg="3" md="4" sm="4">
            <h5>{{ $t('Footer.about') }}</h5>
            <ul class="">
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/about" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Footer.story') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/about" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Footer.mission') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/about" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Footer.about') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/jobs" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Footer.jobs') }}
                </router-link>
              </li>
            </ul>
          </v-col>

          <v-col cols="6" lg="3" md="4" sm="4">
            <h5>{{ $t('Footer.contact') }}</h5>
            <ul class="">
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/contactus" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  {{ $t('Footer.contact') }}
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/contact" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  info@tebaba.com
                </router-link>
              </li>
              <li class="my-2">
                <div class="mx-2"></div>
                <router-link to="/privacy" class="d-flex align-items-start">
                  <v-icon class="px-0.5 pt-1"></v-icon>
                  0123456789
                </router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div class="bottom-foot py-0">
      <div class="container text-center">
        <p>&copy; {{ $t('Footer.rights') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {
        facebook: 'fab fa-facebook-f',
        twitter: 'fab fa-twitter',
        linked: 'fab fa-linkedin-in',
        insta: 'fab fa-instagram',
        ytube: 'fab fa-youtube',
        arrow: 'far fa-long-arrow-alt-right',
      },
    }
  },
  methods: {
    openLink(page) {
      this.$router.push(`/${page}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  // margin-top: 50px;

  .links {
    color: #33a4d5;
    h5 {
      font-size: 1.2vw;
      font-weight: 500;
      position: relative;
      padding: 5px 0;
    }
    ul {
      padding: 0;
      li {
        overflow: hidden;
        color: #1a1818;
        a {
          text-align: center;
          color: #707070;
        }
        .input-group {
          .form-control {
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
            outline: none !important;
            box-shadow: 3px 3px 6px rgba($color: #000000, $alpha: 0.16) inset;
          }
          .btn-send {
            background-color: #33a4d5;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            width: 105px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            outline: none !important;
          }
        }
      }
    }
  }
  .social-links {
    .slink {
      background: #a6dd49;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 24px;
      border-radius: 18px 0 18px 18px;

      &:nth-child(1) {
        &:hover {
          a {
            i {
              color: #005fad !important;
            }
          }
        }
      }
      &:nth-child(2) {
        &:hover {
          a {
            i {
              color: #00a4ad !important;
            }
          }
        }
      }
      &:nth-child(3) {
        &:hover {
          a {
            i {
              color: #06c5cc !important;
            }
          }
        }
      }
      &:nth-child(4) {
        &:hover {
          a {
            i {
              color: #cc0662 !important;
            }
          }
        }
      }
      &:nth-child(5) {
        &:hover {
          a {
            i {
              color: #df0101 !important;
            }
          }
        }
      }
    }
  }
  .bottom-foot {
    background: #30b5ec;

    p {
      margin: 0;
      color: #fff;
      font-weight: normal;
    }
  }
}
</style>
