<template>
  <v-row class="banner-wrap m-0" justify="center" align="center">
    <!-- <v-col cols="12" xl="6" lg="7" md="9" sm="12" class="banner__left pt-0 pl-0">
      <div class="item px-8">
        <div class="wrap">
          <h2><span>نحن نزودكم</span></h2>
          <h2><span>بأدوات و خدمات طبية</span></h2>
          <h2><span>تجعل عملكم أكثر إيجابية</span></h2>
          <p>
            إن تخصص اللغة العربية واحد من أهم وأكثر التخصصات التي تجذب الدراسين لدراستها ويمثل أحد أهم العلوم وذلك نظرًا
            لما للغة العربية من أهمية بالغة ولا سيّما أنها لغة القرآن الكريم وهذا من شأنه أن يمنح اللغة العربية امتيازًا
            يخصّها ويميزها عن غيرها من اللغاتلأخرى. حيث تتميز اللغة العربية بعدة سمات متميزة وذلك مثل وجود حرف الضاد وهو
            الحرف الذي لا يوجد في أي لغة أخرى وبهذا سميت اللغة العربية بلغة الضاد، بالإضافة إلى وفرة كل من المعاني
            والتعابير المختلفة وفيض من كل من الألفاظ والمترادفات في اللغة العربية، بالتالي ومرونتها وهذا بدوره أن يجعل
            اللغة العربية سهلة على الناطقين بها وكذلك على من يرغب باكتسابها كأحد اللغات التي يود تعلمها أيضًا.
          </p>
          <v-btn class="btn-contact" color="#33A4D5">تواصل معنا</v-btn>
        </div>
      </div>
    </v-col>
    <v-col cols="12" xl="6" lg="5" md="12" sm="12" class="banner__right pt-0 px-0">
      <v-img src="@/assets/images/tebaba/banner.png" max-width="600px" class="mr-auto" contain eager></v-img>
    </v-col> -->
    <v-col cols="12" class="banner_wrapper">
      <h2>{{ $t('Video') }}</h2>
    </v-col>
  </v-row>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.banner-wrap {
  position: relative;
  width: 100%;
  // height: 888px;
  min-height: calc(100vh - 90px);
  background-color: #a6dd49;
  .banner_wrapper {
    text-align: center;
    h2 {
      color: #fff;
    }
  }
  .banner__left {
    .item {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;

      .wrap {
        // width: 80%;
        margin-top: 150px;
        margin-left: 20%;
        transform: translate(0%, 0%);
        h2 {
          font-weight: normal;
          // font-size: 3.5vw;
          span {
            color: #33a4d5;
          }
          &:nth-child(2) {
            span {
              color: #a6dd49;
            }
          }
          &:nth-child(3) {
            span {
              color: #707070;
            }
          }
        }
      }

      p {
        max-height: 163px;
        color: #707070;
        font-weight: normal;
        overflow-y: hidden;
        // font-size: 1vw;
        // line-height: 1.5rem;
        margin: 30px 0;
      }
    }
    .btn-contact {
      margin-top: 60px;
      min-width: 220px;
      border-bottom-left-radius: 50px;
      border-top-right-radius: 50px;
      font-weight: bold;
    }
  }
  .banner__right {
    position: relative;
    z-index: 99999;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .clients {
    overflow-x: scroll;
  }
}
.banner-alt {
  position: relative;
  width: 100%;
  // height: 50vh;
  img {
    width: 100%;
    height: 100%;
  }
  // background-image: url("../assets/images/card2.png");
  // background-image: linear-gradient(#ff0, #00f);
  // background-size: cover;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 20%;
  //   max-height: 94px;
  //   background: #000;
  //   background: url("../assets/images/wave2.png") no-repeat;
  //   background-size: 100%;
  // background-position: center;
  // mix-blend-mode: screen;
  // }
}
</style>
