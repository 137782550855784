<template>
  <header>
    <nav>
      <div id="navbar">
        <div id="logo" class="reverse">
          <div class="mobile-btn" style="font-size: 30px; cursor: pointer; font-weight: bold" @click="openNav">
            &#9776;
          </div>
          <router-link class="navbar-brand" to="/"><img src="../assets/images/tebaba/icon.png" alt="" width="100px" />
          </router-link>
        </div>
        <div id="links">
          <router-link to="/">{{ $t('Home') }}</router-link>
          <router-link to="/about">{{ $t('About') }}</router-link>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ $t('Products') }}
              </span>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link to="/products/bedfont">bedfont</router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <router-link to="/products/safer">SAFER</router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <router-link to="/products/potok">potok</router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <router-link to="/contactus">{{ $t('Contact') }}</router-link>
        </div>
        <div>
          <LanguageSwitcher></LanguageSwitcher>
        </div>
      </div>
    </nav>
    <!-- Mobile Menu -->
    <div id="mySidenav" class="sidenav">
      <a style="cursor: pointer" class="closebtn" @click="closeNav">&times;</a>
      <router-link v-for="(link, i) in links" :key="i" :to="link.to">{{ link.title }}</router-link>
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from '@/layouts/components/LanguageSwitcher.vue'

export default {
  data() {
    return {
      links: [
        { title: this.$t('Home'), to: '/', icon: 'fas fa-home' },
        { title: this.$t('About'), to: '/about', icon: 'fas fa-info-circle' },
        { title: this.$t('Products'), to: '/products', icon: 'fas fa-hand-heart' },
        { title: this.$t('Contact'), to: '/contactus', icon: 'fas fa-lightbulb-on' },
      ],
      auth: 'Request Program',
      icons: {
        menu: 'fal fa-bars',
      },
      opened: false,
    }
  },
  methods: {
    signOut() {
      localStorage.removeItem('access_token')
      this.$router.push('/auth')
    },
    openNav() {
      document.getElementById('mySidenav').style.width = '250px'
    },
    closeNav() {
      document.getElementById('mySidenav').style.width = '0'
    },
  },
  components: { LanguageSwitcher },
}
</script>
<style lang="scss" scoped>
#navbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 90px;
  grid-auto-flow: column;
  //   background-color: #fcfcfc;
  //   background-image: linear-gradient(to bottom, #30b5ec, #fff);
  background: linear-gradient(180deg,
      rgba(48, 181, 236, 1) -100%,
      rgba(48, 181, 236, 1) -100%,
      rgba(255, 255, 255, 1) 90%);
  grid-gap: 1.5em;
  padding: 0 40px;
  //   -webkit-box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
  //   -moz-box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
  //   box-shadow: -1px 4px 5px 0px rgba(209, 205, 209, 1);
}

a {
  color: #707070 !important;
}

#logo {
  width: 150px;
  display: grid;
  justify-content: center;
  align-content: center;
}

.btn-products {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.logo span {
  color: #0474bc;
}

#links {
  display: grid;
  justify-content: right;
  align-content: center;
  grid-auto-flow: column;
  grid-gap: 1.5em;
}

#links span,
#links a {
  text-decoration: none;
  color: #707070;
  // text-transform: uppercase;
  font-weight: normal;
  font-family: 'Neo Sans Arabic', sans-serif !important;
}

#links a:hover {
  color: #0474bc;
}

/* =======================
******* Mobile Menu ******
========================*/
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: #707070;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 32px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #fff;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

a {
  font-family: 'Open Sans', sans-serif;
}

/* Mobile to Tablet  */
@media (min-width: 320px) and (max-width: 767px) {

  #links,
  .mobile-menu {
    display: none;
  }

  #logo {
    display: inline-grid;
    grid-template-columns: auto auto;
    justify-content: center;
    align-items: center;
    grid-gap: 0px;
  }
}

/* Tablet to Desktop */
@media (min-width: 768px) {
  .mobile-btn {
    display: none;
  }
}
</style>
