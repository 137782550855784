<template>
  <div>
    <v-icon size="26" @click="handleChange">
      {{ lang == 'ar' ? icons.mdiWeb : icons.mdiWeb }}
    </v-icon>
  </div>
</template>

<script>
import i18n from '@/i18n'

export default {
  data() {
    const lang = localStorage.getItem('lang') || 'ar'

    return {
      lang,
      isAr: false,
      icons: {
        mdiWeb: 'fal fa-globe',
      },
    }
  },
  methods: {
    handleChange() {
      if (this.lang == 'en') {
        localStorage.setItem('lang', 'ar')
        i18n.locale = 'ar'
        localStorage.setItem('rtl', true)
        this.isAr = true
      } else {
        localStorage.setItem('lang', 'en')
        i18n.locale = 'en'
        localStorage.removeItem('rtl')
        this.isAr = false
      }
      window.location.reload()
    },
  },
}
</script>

<style>
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  outline: none;
  border-radius: 3px;
  color: #999;
}

/* remove default arrow in IE */
select::-ms-expand {
  display: none;
}
</style>
