<template>
  <div class="about">
    <div class="content">
      <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
        <nav aria-label="breadcrumb" class="breadcrumb-wrap">
          <h3>{{ $t('About') }}</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t('Home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('About') }}</li>
          </ol>
        </nav>
        <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
      </div>

      <div class="business-wrap container py-12 mb-6">
        <v-row justify="center" align="center" class="business">
          <v-col class="item__wrap" cols="12" xl="5" lg="6" md="12" sm="12">
            <v-img src="@/assets/images/tebaba/who.png" max-width="600px" class="mx-auto"></v-img>
          </v-col>
          <v-col class="item__wrap" cols="12" xl="7" lg="6" md="12" sm="12">
            <div class="content pa-8">
              <h3>{{ $t('About') }}</h3>
              <ul class="mt-6">
                <li class="mt-5">
                  {{ $t('WWA.founded1') }}
                </li>
                <li class="mt-5">
                  {{ $t('WWA.founded2') }}
                </li>
                <li class="mt-5">
                  {{ $t('WWA.founded3') }}
                </li>
              </ul>
              <v-btn class="btn-contact" color="#30B5EC" to="/contactus">{{ $t('Btns.contact') }}</v-btn>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="business-wrap container py-12 mb-6">
        <v-row justify="center" align="center" class="business">
          <v-col class="item__wrap" cols="12" xl="5" lg="6" md="12" sm="12">
            <v-img src="@/assets/images/tebaba/abt_2.png" max-width="600px" class="mx-auto"></v-img>
          </v-col>
          <v-col class="item__wrap" cols="12" xl="7" lg="6" md="12" sm="12">
            <div class="content pa-8">
              <div>
                <h3>{{ $t('Headings.vision') }}</h3>
                <p class="">
                  {{ $t('Headings.vision_p') }}
                </p>
              </div>
              <div>
                <h3>{{ $t('Headings.mission') }}</h3>
                <p class="">
                  {{ $t('Headings.mission_p') }}
                </p>
              </div>
              <div>
                <h3>{{ $t('Headings.value') }}</h3>
                <p class="">
                  {{ $t('Headings.value_p') }}
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="bg-sky d-none d-xl-block d-lg-block d-md-block"></div>
      </div>

      <Subs></Subs>
    </div>
  </div>
</template>
<script>
import Subs from '@/components/Subs.vue'

export default {
  components: { Subs },
}
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  .yellow {
    background-color: rgba($color: #fecb00, $alpha: 0.3) !important;
  }
  .business-wrap {
    position: relative;
    .bg-sky {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 400px;
      border-radius: 37px;
      background: linear-gradient(rgba($color: #fff, $alpha: 0.25), #30b5ec);
      opacity: 39%;
      z-index: 0;
    }
    .business {
      position: relative;
      z-index: 4;
      .item__wrap {
        .img_card {
          width: 410px;
          height: 300px;
          border-radius: 202px 0 202px 202px;
        }
        .info_card {
          h5 {
            color: #33a4d5;
          }
          h6 {
            color: #707070;
          }
        }
        .content {
          p {
            margin-top: 0;
          }
          ul {
            li {
              list-style: circle;
            }
          }
        }
      }
    }
  }
  // .abt__t {
  //   // width: 90%;
  //   // margin: auto;
  //   margin-top: 70px;
  //   h4 {
  //     color: #000;
  //     font-size: 2.9vw;
  //     font-weight: bold;
  //     position: relative;
  //     span {
  //       font-weight: lighter;
  //       color: #33a4d5;
  //     }
  //     &:before {
  //       content: "";
  //       width: 110px;
  //       position: absolute;
  //       top: 120%;
  //       left: 50%;
  //       transform: translate(-50%);

  //       border-bottom: 8px solid #33a4d5;
  //     }
  //   }
  //   p {
  //     font-size: 1.4vw;
  //     margin-top: 70px;
  //     line-height: 40px;
  //     font-weight: lighter;
  //     color: #707070;
  //   }
  // }
  .btn-contact {
    margin-top: 60px;
    min-width: 220px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    font-weight: bold;
  }
}
</style>
