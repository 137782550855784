<template>
  <div class="business-wrap container py-12 mb-6 text-center">
    <h3>{{ $t('Headings.products') }}</h3>
    <v-row justify="center" align="center" class="business">
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/bed.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/safer.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/potok.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/food.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/fda.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
      <v-col class="item__wrap text-center" cols="6" xl="4" lg="4" md="4" sm="6">
        <v-img src="@/assets/images/tebaba/iso.png" class="mx-auto" max-width="200px"></v-img>
      </v-col>
    </v-row>
    <!-- <v-row justify="center" align="center" class="solutions__wrapper">
      <v-col cols="12" lg="6" md="12" class="item__wrap mb-4 text-start pa-8">
        <v-img
          src="@/assets/images/tebaba/choose.png"
          max-width="500px"
        ></v-img>
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <v-row justify="center" align="center">
          <v-col class="item__wrap mb-2" cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="item pa-6">
              <div class="icon d-flex justify-center align-center mb-4">
                <div class="ico_con d-flex justify-center align-center mx-auto">
                  <v-icon color="#fff">{{ icons.team }}</v-icon>
                </div>
              </div>
              <h6>خدمة العملاء 24/7</h6>
              <p>
                إن تخصص اللغة العربية واحد من أهم وأكثر التخصصات التي تجذب
                الدراسين لدراستها .
              </p>
            </div>
          </v-col>
          <v-col class="item__wrap mb-2" cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="item pa-6">
              <div class="icon d-flex justify-center align-center mb-4">
                <div class="ico_con d-flex justify-center align-center mx-auto">
                  <v-icon color="#fff">{{ icons.dollar }}</v-icon>
                </div>
              </div>
              <h6>سهولة الدفع</h6>
              <p>
                إن تخصص اللغة العربية واحد من أهم وأكثر التخصصات التي تجذب
                الدراسين لدراستها .
              </p>
            </div>
          </v-col>
          <v-col class="item__wrap mb-2" cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="item pa-6">
              <div class="icon d-flex justify-center align-center mb-4">
                <div class="ico_con d-flex justify-center align-center mx-auto">
                  <v-icon color="#fff">{{ icons.car }}</v-icon>
                </div>
              </div>
              <h6>توصيل للمراكز</h6>
              <p>
                إن تخصص اللغة العربية واحد من أهم وأكثر التخصصات التي تجذب
                الدراسين لدراستها .
              </p>
            </div>
          </v-col>
          <v-col class="item__wrap mb-2" cols="12" xl="6" lg="6" md="6" sm="6">
            <div class="item pa-6">
              <div class="icon d-flex justify-center align-center mb-4">
                <div class="ico_con d-flex justify-center align-center mx-auto">
                  <v-icon color="#fff">{{ icons.return }}</v-icon>
                </div>
              </div>
              <h6>الإستبدال والإسترجاع</h6>
              <p>
                إن تخصص اللغة العربية واحد من أهم وأكثر التخصصات التي تجذب
                الدراسين لدراستها .
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {
        hand: 'fas fa-hands-helping',
        coin: 'fal fa-coins',
        bag: 'fal fa-briefcase',
        check: 'fal fa-badge-check',
        dollar: 'fal fa-comment-alt-dollar',
        car: 'fal fa-truck',
        return: 'fal fa-briefcase-medical',
        team: 'fal fa-users',
      },
    }
  },
}
</script>

<style lang="scss">
.business-wrap {
  .business {
    .item__wrap {
      h3 {
        color: #707070 !important;
      }

      p {
        color: #707070 !important;
        margin-top: 30px;
      }
    }
  }
}

.solutions__wrapper {
  margin-top: 80px;

  .item__wrap {
    min-height: 200px;

    .item {
      position: relative;
      min-height: 150px;
      border-radius: 17px;
      box-shadow: 0 0 30px rgba($color: #707070, $alpha: 0.1);

      h6 {
        margin-top: 15px;
        font-weight: 500;
        // font-size: 1.1vw;
        color: #30b5ec;
      }

      p {
        color: #707070;
        // font-size: 1vw;
      }

      .icon {
        position: absolute;
        top: -30px;
        width: 60px;
        height: 60px;
        background-color: #fff;
        padding: 3px;
        box-shadow: 0 3px 10px rgba($color: #707070, $alpha: 0.1);

        .ico_con {
          width: 90%;
          height: 90%;
          background-color: rgba($color: #30b5ec, $alpha: 0.2);
          margin: 0 auto;
          border-radius: 47px 0 47px 47px;
          //   border: 5px solid #fff;
        }

        border-radius: 47px 0 47px 47px;

        // border: 5px solid #fff;
        i {
          color: #30b5ec !important;
        }
      }
    }
  }
}
</style>
