<template>
  <div class="business-wrap container py-lg-12 mb-6">
    <v-row
      justify="center"
      align="center"
      class="business"
      :style="{
        'background-image': 'url(' + require('@/assets/images/tebaba/subs.png') + ')',
      }"
    >
      <v-col class="item__wrap pa-lg-8" cols="12" lg="8" md="12" sm="12">
        <div class="content text-center">
          <h5>{{ $t('Headings.subscribe') }}</h5>
          <div class="subs_wrapper">
            <button class="btn-subs" type="button">{{ $t('Btns.follow') }}</button>
            <input type="text" class="form-control" />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.business-wrap {
  background-color: #a6dd49;
  border-radius: 15px;
  min-height: 200px;
  .business {
    background-size: contain;
    background-position: center;
    .item__wrap {
      .content {
        color: #fff;
        .subs_wrapper {
          position: relative;
          margin-top: 30px;
          input {
            width: 80%;
            margin: 0 auto;
            position: relative;
            z-index: 0;
            height: 50px;
            border-radius: 37px;
          }
          .btn-subs {
            width: 140px;
            height: 40px;
            border-radius: 31px;
            position: absolute;
            top: 50%;
            right: 11%;
            transform: translateY(-50%);
            z-index: 9;
            background-color: #a6dd49;
          }
        }
      }
    }
  }
}
</style>
