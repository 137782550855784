<template>
  <div class="container-fluid home px-0">
    <Banner></Banner>
    <Solutions class="py-lg-12"></Solutions>
    <Choose></Choose>
    <Subs></Subs>
  </div>
</template>

<script>
import Banner from '@/components/Banner.vue'
import Solutions from '@/components/Solutions.vue'
import Choose from '@/components/Choose.vue'
import Subs from '@/components/Subs.vue'
// @ is an alias to /src
// import locomotiveScroll from "locomotive-scroll";

export default {
  name: 'Home',
  components: { Banner, Solutions, Choose, Subs },
}
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
</style>
