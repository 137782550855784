<template>
  <div class="solutions-wrap ">
    <v-container class="solutions text-center">
      <v-row justify="center" align="center" class="solutions__wrapper">
        <v-col cols="12" lg="6" md="12">
          <v-row justify="center" align="center">
            <v-col class="item__wrap mt-4" cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="item pa-6">
                <div class="icon d-flex justify-center align-center mb-4 mx-auto">
                  <v-icon color="#fff">{{ icons.team }}</v-icon>
                </div>
                <h3>{{ $t('Solutions.prof_team') }}</h3>

                <!-- <h3>فريق محترف</h3> -->
                <p>
                  {{ $t('Solutions.lorem') }}
                </p>
                <v-btn color="#A6DD49" class="btn-more">{{ $t('Btns.read_more') }}</v-btn>
              </div>
            </v-col>
            <v-col class="item__wrap mt-4" cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="item pa-6">
                <div class="icon d-flex justify-center align-center mb-4 mx-auto">
                  <v-icon color="#fff">{{ icons.check }}</v-icon>
                </div>
                <h3>{{ $t('Solutions.qual_acc') }}</h3>
                <p>
                  {{ $t('Solutions.lorem') }}
                </p>
                <v-btn color="#A6DD49" class="btn-more">{{ $t('Btns.read_more') }}</v-btn>
              </div>
            </v-col>
            <v-col class="item__wrap mt-4" cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="item pa-6">
                <div class="icon d-flex justify-center align-center mb-4 mx-auto">
                  <v-icon color="#fff">{{ icons.box }}</v-icon>
                </div>
                <h3>{{ $t('Solutions.good_pack') }}</h3>

                <p>
                  {{ $t('Solutions.lorem') }}
                </p>
                <v-btn color="#A6DD49" class="btn-more">{{ $t('Btns.read_more') }}</v-btn>
              </div>
            </v-col>
            <v-col class="item__wrap mt-4" cols="12" xl="6" lg="6" md="6" sm="6">
              <div class="item pa-6">
                <div class="icon d-flex justify-center align-center mb-4 mx-auto">
                  <v-icon color="#fff">{{ icons.plane }}</v-icon>
                </div>
                <h3>{{ $t('Solutions.inter_made') }}</h3>
                <p>
                  {{ $t('Solutions.lorem') }}
                </p>
                <v-btn color="#A6DD49" class="btn-more">{{ $t('Btns.read_more') }}</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="12" class="item__wrap text-start pa-8">
          <div class="content pa-8">
              <h3>{{ $t('About') }}</h3>
              <ul class="mt-6">
                <li class="mt-5">
                  {{ $t('WWA.founded1') }}
                </li>
                <li class="mt-5">
                  {{ $t('WWA.founded2') }}
                </li>
                <li class="mt-5">
                  {{ $t('WWA.founded3') }}
                </li>
              </ul>
              <v-btn class="btn-contact" color="#A6DD49">{{ $t('Btns.contact') }}</v-btn>
            </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {
        hand: 'fas fa-hands-helping',
        team: 'fal fa-users',
        check: 'fal fa-badge-check',
        box: 'fal fa-box-check',
        plane: 'fal fa-plane',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.solutions-wrap {
  .solutions {
    h4 {
      color: #000;
      font-size: 2.9vw;
      font-weight: bold;
      position: relative;
      span {
        font-weight: lighter;
        color: #33a4d5;
      }
      &:before {
        content: '';
        width: 110px;
        position: absolute;
        top: 120%;
        left: 50%;
        transform: translate(-50%);
        border-bottom: 8px solid #33a4d5;
      }
    }
    .solutions__wrapper {
      margin-top: 60px;
      .item__wrap {
        h2 {
          color: #707070;
          // font-size: 3.2vw;
          font-weight: normal;
        }
        p {
          color: #707070;
          // font-size: 1.1vw;
          margin-top: 30px;
        }
        .btn-contact {
          margin-top: 60px;
          min-width: 220px;
          border-bottom-left-radius: 50px;
          border-top-right-radius: 50px;
          font-weight: bold;
        }
        .item {
          min-height: 250px;
          text-align: center;
          border: 1px solid #a6dd49;
          border-radius: 17px;

          // box-shadow: 0 0 6px #A6DD49;
          h3 {
            font-weight: normal;
            // font-size: 1.1vw;
            color: #707070;
          }
          p {
            color: #707070;
            // font-size: 1vw;
          }
          .icon {
            width: 50px;
            height: 50px;
            background-color: #a6dd49;
            margin: 0 auto;
            border-radius: 50%;
            i {
              color: #fff !important;
            }
          }
          .btn-more {
            border-radius: 35px;
            min-width: 120px;
            font-weight: bold;
          }
        }
        &:nth-child(1) {
          .item {
            &:hover {
              background-color: #a6dd49;
              .icon {
                background-color: #fff !important;
                i {
                  color: #a6dd49 !important;
                }
              }
              h3 {
                color: #fff;
              }
              p {
                color: #fff;
              }
              .btn-more {
                background-color: #fff !important;
                color: #a6dd49 !important;
              }
            }
          }
        }
        &:nth-child(2) {
          .item {
            border: 1px solid #ffbc00 !important;
            .icon {
              width: 50px;
              height: 50px;
              background-color: #ffbc00;
              margin: 0 auto;
              border-radius: 50%;
            }
            .btn-more {
              border-radius: 35px;
              min-width: 120px;
              font-weight: bold;
              background-color: #ffbc00 !important;
            }
            &:hover {
              background-color: #ffbc00;
              .icon {
                background-color: #fff !important;
                i {
                  color: #ffbc00 !important;
                }
              }
              h3 {
                color: #fff;
              }
              p {
                color: #fff;
              }
              .btn-more {
                background-color: #fff !important;
                color: #ffbc00 !important;
              }
            }
          }
        }
        &:nth-child(3) {
          .item {
            border: 1px solid #30b5ec !important;
            .icon {
              width: 50px;
              height: 50px;
              background-color: #30b5ec;
              margin: 0 auto;
              border-radius: 50%;
            }
            .btn-more {
              border-radius: 35px;
              min-width: 120px;
              font-weight: bold;
              background-color: #30b5ec !important;
            }
            &:hover {
              background-color: #30b5ec;
              .icon {
                background-color: #fff !important;
                i {
                  color: #30b5ec !important;
                }
              }
              h3 {
                color: #fff;
              }
              p {
                color: #fff;
              }
              .btn-more {
                background-color: #fff !important;
                color: #30b5ec !important;
              }
            }
          }
        }
        &:nth-child(4) {
          .item {
            border: 1px solid #d258ff !important;
            .icon {
              width: 50px;
              height: 50px;
              background-color: #d258ff;
              margin: 0 auto;
              border-radius: 50%;
            }
            .btn-more {
              border-radius: 35px;
              min-width: 120px;
              font-weight: bold;
              background-color: #d258ff !important;
            }
            &:hover {
              background-color: #d258ff;
              .icon {
                background-color: #fff !important;
                i {
                  color: #d258ff !important;
                }
              }
              h3 {
                color: #fff;
              }
              p {
                color: #fff;
              }
              .btn-more {
                background-color: #fff !important;
                color: #d258ff !important;
              }
            }
          }
        }
      }
    }
  }
}
</style>
