import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from './assets/i18n/en.json'
import ar from './assets/i18n/ar.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    en,
    ar,
  },
})
