import axios from 'axios'
import { vm } from '../../main'

const state = {
  user: null,
  login: {
    error: 'Something Went Wrong , Try again !',
    loading: false,
    dialoge: false,
  },
}
const getters = {
  getError(state) {
    return state.login.error
  },
  getState(state) {
    return state.login.loading
  },
  getDialog(state) {
    return state.login.dialoge
  },
  getUser(state) {
    return state.user
  },
}
const actions = {
  async LogIn({ state }, User) {
    state.login.loading = true
    await axios
      .post('/login', {
        email: User.email,
        password: User.password,
        type: 'customer',
      })
      .then(res => {
        if (res.data.code === 401) {
          state.login.loading = false
          state.login.dialoge = true
          state.login.error = 'Admin Can not access to customer dashboard !'
        } else if (res.data.code === 200) {
          state.login.loading = false
          state.user = res.data.response.user
          localStorage.setItem('token', res.data.response.token)
          localStorage.setItem('user', JSON.stringify(res.data.response.user))
          window.location.replace('/')
        }
      })
      .catch(err => {
        state.login.loading = false
        state.login.dialoge = true
        state.login.error = 'Invalid Credentials !'
      })
  },
  LogOut() {
    axios
      .post('/logout')
      .then(res => {
        localStorage.removeItem('token')
        localStorage.removeItem('company')
        localStorage.removeItem('user')
        window.location.replace('/login')
        state.user = {}
      })
      .catch(error => {
        localStorage.removeItem('token')
        localStorage.removeItem('company')
        localStorage.removeItem('user')
        window.location.replace('/login')
        state.user = {}
      })
  },
}
const mutations = {
  DialogApperence(state, payload) {
    state.login.dialoge = payload
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
}
